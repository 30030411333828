<template>
  <div class="merchant-content">
    <div class="merchant-content__in">
      <Header />
      <Tabs />
      <router-view />
    </div>
    <back-to-top bottom="50px" right="50px">
      <div class="btn btn__top">
        <b-icon-arrow-up-circle />
      </div>
    </back-to-top>
    <Footer />
  </div>
</template>
<script>
import '../theme/main.scss'
import Header from '@/components/main-layout/header/Header.vue'
import Footer from '@/components/main-layout/footer/Footer.vue'
import Tabs from '@/components/main-layout/tabs/Tabs.vue'
import AuthService from '@/services/auth/auth.service'
export default {
  name: 'MainView',
  components: {
    Footer,
    Header,
    Tabs,
  },
  mounted() {
    document.body.scrollTo(0, 0)
    window.scrollTo(0, 0)
    if (
      localStorage.Email &&
      localStorage.Password &&
      !this.$system.user.isAuthenticated &&
      this.$router.currentRoute.path !== '/loginviewer'
    ) {
      this.loginUser()
    }
  },
  methods: {
    async loginUser() {
      try {
        if (
          localStorage.Email &&
          localStorage.Password &&
          !this.$system.user.isAuthenticated
        ) {
          let user = {
            Email: localStorage.Email,
            Password: this.$system.decipher(
              process.env.NODE_ENV,
              localStorage.Password,
            ),
          }
          const response = await AuthService.authUser(user)
          if (response && response.status == 'success' && response.body) {
            this.$system.login(
              user,
              response.body.Id,
              response.body.FirstName,
              response.body.LastName,
            )
            if (this.$router.currentRoute.path == '/login') {
              this.$router.push('/')
            }
          } else this.$system.logout()
        } else this.$system.logout()
      } catch (err) {
        this.$system.logout()
      }
    },
  },
}
</script>
