<template>
  <div v-if="$system.user.isAuthenticated" class="tabs mt-4 mb-2">
    <div class="container">
      <div class="row">
        <ul class="tabs__links p-3">
          <div class="tabs__links__left">
            <li v-for="link in links" :key="link.name">
              <router-link
                :to="link.route"
                class="link"
                active-class="active"
                exact
              >
                {{ link.name }}
              </router-link>
            </li>
          </div>
          <div
            v-if="$system.user.FirstName || $system.user.LastName"
            class="tabs__links__right"
          >
            Hi,
            <router-link to="/profile" class="user">
              <template v-if="$system.user.FirstName">
                {{ $system.user.FirstName }}
              </template>
              <template v-if="$system.user.LastName">
                {{ $system.user.LastName }}
              </template>
            </router-link>
            <b-icon-person-lines-fill />
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import './Tabs.scss'

export default {
  name: 'Tabs',
  data() {
    return {
      links: [
        {
          name: 'Accounts',
          route: '/accounts',
        },
        {
          name: 'Repayments',
          route: '/repayments',
        },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>
